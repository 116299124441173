<template>
  <div class="users-setting mt-3">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="header-user">
        <div
          class="activer-desactiver-user left"
          v-if="computedusersSpecifierVoirChampsAndSuperAdminfunction"
        >
          <v-switch
            :label="
              !activeUser
                ? $i18n.locale == 'fr'
                  ? 'Utilisateurs Désactivés'
                  : 'Disabled Users'
                : $i18n.locale == 'fr'
                ? 'Utilisateurs Actifs'
                : 'Active users'
            "
            color="#3A167F"
            @change="handlClickActive"
            v-model="activeUser"
            :class="{ 'color-bold': activeUser }"
          >
          </v-switch>
        </div>

        <div class="right block-flex-align-space-between">
          <v-btn
            v-if="currentUser && currentUser.isSuper === 1"
            @click="exportClickUser"
            dark
            class="mr-2"
            color="#5C2DD3"
            :title="$t('exportExcel')"
          >
            <span class="font-sz-11 bold-700 font-text">
              {{
                $i18n.locale == 'fr' ? 'EXPORTER VERS EXCEL' : 'Export to Excel'
              }}
            </span>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-text-field
            v-model="full_name"
            @input="filter"
            clearable
            :prepend-inner-icon="'mdi-magnify'"
            :label="$t('search')"
            outlined
            :placeholder="$t('search')"
            :persistent-placeholder="true"
            dense
            hide-details
            color="#5C2DD3"
          ></v-text-field>
        </div>
      </div>
      <div class="table-users">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? computedFields : computedFieldsEn"
          :items="users"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :server-items-length="usersCount"
          @update:page="handlePaginationChange"
          @update:items-per-page="SortUsers"
          :footer-props="{
            'items-per-page-options': perPageList
          }"
          class="table-users"
          disable-sort
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getUserLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- HEADERS TABLE -->
          <!-- <template v-slot:[`header`]="{ props }">
            <tr class="header-users">
              <th
                v-for="header in props.headers"
                :key="header.text"
                :style="{ width: header.width, padding: header.padding }"
              >
                <div
                  :class="{
                    'd-flex justify-content-center align-items-center':
                      header.sortable === true
                  }"
                >
                  <span
                    :style="{
                      width: header.value === 'actions' ? header.width : 'auto'
                    }"
                  >
                    {{ header.text }}</span
                  > -->

          <!-- <font-awesome-icon
                  v-if="header.sortable === true"
                  icon="arrow-up"
                  :class="
                    header.value === sort_by
                      ? 'style-arrow-selected'
                      : 'style-arrow-not-selected'
                  "
                />
                <font-awesome-icon
                  v-if="header.sortable === true"
                  icon="arrow-down"
                  :class="
                    header.value === sort_by
                      ? 'style-arrow-selected'
                      : 'style-arrow-not-selected'
                  "
                /> -->
          <!-- </div>
              </th>
            </tr>
          </template> -->
          <!-- BODY TABLE -->

          <template v-slot:[`item.first_name`]="{ item }">
            <td class="custom-cell">
              <div
                class="font-div"
                :class="{
                  'icon-exclamation':
                    item.pipe_tech_deleted === 1 ||
                    item.pipe_user_deleted === 1 ||
                    item.pipe_user_active === 0
                }"
                :title="
                  item.pipe_tech_deleted === 1
                    ? $i18n.locale === 'fr'
                      ? 'Technicien supprimé de pipedrive'
                      : 'Technician removed from pipedrive'
                    : item.pipe_user_active == 0
                    ? $i18n.locale === 'fr'
                      ? 'Utilisateur pipedrive n\'est plus actif'
                      : 'User pipedrive is no longer active'
                    : item.pipe_user_deleted === 1
                    ? $i18n.locale === 'fr'
                      ? 'Utilisateur pipedrive est supprimé'
                      : 'User pipedrive is deleted'
                    : ''
                "
              >
                {{ item.full_name }}
              </div>
            </td>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <td class="custom-cell">
              {{ item.email }}
            </td>
          </template>
          <template v-slot:[`item.code_client`]="{ item }">
            <td class="custom-cell">
              {{ item.code_client }}
            </td>
          </template>
          <template v-slot:[`item.societe`]="{ item }">
            <td class="custom-cell">
              {{ item.societe }}
            </td>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">
              {{ item.type | typeFormat }}
            </td>
          </template>
          <template v-slot:[`item.team_name`]="{ item }">
            <td class="custom-cell">
              <div v-if="item.team" class="font-div">
                <span>
                  {{ item.team.data.name }}
                </span>
                <br />
                <span class="color-crm font-sz-12 bold-500">
                  ( {{ item.team.data.type }} )
                </span>
                <span
                  class="color-crm font-sz-12 bold-500"
                  v-if="item.depot_id != null && item.team"
                >
                  - ( {{ item.depot.data.name }} )
                </span>
              </div>
            </td>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <td class="custom-cell">
              {{ item ? item.created_at.split(',')[0] : '' }}
            </td>
          </template>
          <template v-slot:[`item.created_by`]="{}">
            <td class="custom-cell">
              {{ ' - ' }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td class="custom-cell">
              <div class="actions"></div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#5C2DD3" dark v-bind="attrs" v-on="on" icon>
                    <span class="font-sz-20 mb-2"> ...</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click.prevent="handleClickUpdate(item)">
                    <v-list-item-title class="lettre-one-capitalize">{{
                      $t('update')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      (currentUser && currentUser.isSuper) ||
                        ((computedRegie ||
                          currentUser.type == 'user.commercial' ||
                          currentUser.type == 'user.final') &&
                          item.is_you == 0)
                    "
                    @click.prevent="handleClickDelete(item)"
                  >
                    <v-list-item-title class="lettre-one-capitalize">{{
                      $t('deleted')
                    }}</v-list-item-title>
                  </v-list-item>
                  <div
                    v-if="computedusersSpecifierVoirChampsAndSuperAdminfunction"
                  >
                    <v-list-item
                      @click.prevent="handleClickDesactiveActive(item)"
                      v-if="item.is_active == 1"
                    >
                      <v-list-item-title class="lettre-one-capitalize">{{
                        $t('diabled')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click.prevent="handleClickDesactiveActive(item)"
                      v-if="item.is_active == 0"
                    >
                      <v-list-item-title class="lettre-one-capitalize">{{
                        $t('active')
                      }}</v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- <div class="footer-style-table">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            :total-visible="7"
            color="#5C2DD3"
            item-color="#5C2DD3"
            circle
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="SortUsers"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div> -->
    </div>
    <!-- Export User Modal  -->
    <v-dialog v-model="exportUserModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $t('configUser.export') }}
            {{
              $i18n.locale === 'fr'
                ? 'des ' + $t('configUser.name')
                : $t('configUser.name')
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('exportUserModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="validateExportUser">
            <v-row class="row">
              <v-col class="col1">
                <v-select
                  v-model="userToExport.type"
                  :items="computedFormatTypesUsersExport"
                  no-data-text="Aucun type trouvé."
                  label="Type"
                  item-text="name"
                  item-value="id"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  outlined
                  required
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  dense
                  :persistent-placeholder="true"
                >
                </v-select>
                <v-select
                  :label="$t('equipe')"
                  dense
                  v-model="userToExport.team_type"
                  item-text="name"
                  item-value="id"
                  :items="computedExportUser"
                  outlined
                  :persistent-placeholder="true"
                  :placeholder="$t('search')"
                  required
                  return-object
                  validate-on-blur
                  :rules="[v => !!v || $t('equipe') + ' ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  no-data-text="Aucun type trouvé."
                ></v-select>
                <div class="active-desactive-export">
                  <v-radio-group v-model="userToExport.active" row>
                    <v-radio
                      class="input-checkbox"
                      :label="$t('configUser.active')"
                      color="#5C2DD3"
                      :value="1"
                      hide-details
                    ></v-radio>
                    <v-radio
                      class="input-checkbox"
                      :label="$t('configUser.desactive')"
                      :value="0"
                      color="#5C2DD3"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>

              <v-col class="col2">
                {{
                  $i18n.locale === 'fr'
                    ? 'Liste de colonnes :'
                    : 'List of columns :'
                }}
                <div>
                  <v-checkbox
                    class="input-checkbox msg-error mt-2"
                    :label="item"
                    v-for="(item, index) in attributes"
                    :key="index"
                    :disabled="isDisabled(item)"
                    :value="item"
                    v-model="userToExport.columns[index]"
                    color="#5C2DD3"
                    hide-details
                  ></v-checkbox>
                  <!-- required
                    validate-on-blur
                    :rules="[v => !!v || 'Il faut sélectionner au moins une colonne ID']" -->
                </div>
              </v-col></v-row
            >
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error || requiredColumns" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
              <span class="error-msg" v-if="requiredColumns == true"
                >Il faut sélectionner au moins une colonne.</span
              >
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleExportUser"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('exportUserModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Export User Modal  -->
    <!-- Delete User Modal -->
    <v-dialog v-model="userDeleteModal" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un utilisateur' : 'Delete user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('userDeleteModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? " l'utilisateur" : 'the user'
              })
            }}
            <strong class="name">
              {{ userToDelete ? userToDelete.first_name : '' }}
              {{ userToDelete ? userToDelete.last_name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalSupprimerValider"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('userDeleteModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete User Modal -->
    <v-dialog
      v-model="userDesactiveActiveModal"
      max-width="500"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              userToDesactiver && userToDesactiver.is_active == 1
                ? $i18n.locale === 'fr'
                  ? 'Désactiver un utilisateur'
                  : 'Disable user'
                : $i18n.locale === 'fr'
                ? 'Activer un utilisateur'
                : 'Enable user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('userDesactiveActiveModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div
            v-if="
              userToDesactiver && userToDesactiver.type == 'user.technicien'
            "
          >
            <div
              v-if="
                userToDesactiver &&
                  userToDesactiver.type == 'user.technicien' &&
                  userToDesactiver.is_active == 1
              "
            >
              <p class="font-text ">
                {{ $i18n.locale === 'fr' ? "L'utilisateur" : 'The user' }}
                <strong class="name">
                  {{ userToDesactiver ? userToDesactiver.first_name : '' }}
                  {{
                    userToDesactiver ? userToDesactiver.last_name : ''
                  }}</strong
                >
                {{
                  $i18n.locale === 'fr'
                    ? 'sera désactivé à partir :'
                    : 'Will be deactivated from :'
                }}
              </p>
              <div class="dateDesactiver">
                <v-select
                  :label="
                    $i18n.locale === 'fr' ? 'Semaine de début' : 'Start week'
                  "
                  dense
                  v-model="userToDesactiver.start_week_id"
                  item-text="name"
                  item-value="id"
                  :items="computedWeeks"
                  clearable
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                >
                </v-select>
                <v-select
                  :label="$i18n.locale === 'fr' ? 'Semaine de fin' : 'End week'"
                  dense
                  v-model="userToDesactiver.end_week_id"
                  item-text="name"
                  item-value="id"
                  :items="computedWeeks"
                  outlined
                  clearable
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                >
                </v-select>
              </div>
            </div>
            <div v-else>
              <p class="font-text">
                {{ $i18n.locale === 'fr' ? "L'utilisateur" : 'The user' }}
                <strong class="name">
                  {{ userToDesactiver ? userToDesactiver.first_name : '' }}
                  {{
                    userToDesactiver ? userToDesactiver.last_name : ''
                  }}</strong
                >
                {{
                  $i18n.locale === 'fr'
                    ? 'est désactivé à partir de la semaine'
                    : 'is deactivated starting from this week.'
                }}
                <span v-if="this.userToDesactiver.start_week">
                  <strong class="name">
                    {{ userToDesactiver.start_week.week_nbr }}
                    (
                    {{ userToDesactiver.start_week.first_day_of_week | date }} à
                    {{ userToDesactiver.start_week.last_day_of_week | date }}
                    ) </strong
                  ><span
                    v-if="
                      this.userToDesactiver.start_week &&
                        !this.userToDesactiver.end_week
                    "
                    >.</span
                  >
                  <span
                    v-if="
                      this.userToDesactiver.start_week ==
                        this.userToDesactiver.end_week
                    "
                    >.</span
                  >
                </span>
                <span
                  v-if="
                    this.userToDesactiver.end_week &&
                      this.userToDesactiver.end_week.id !=
                        this.userToDesactiver.start_week.id
                  "
                >
                  {{ $i18n.locale === 'fr' ? ' à la semaine' : 'by the week' }}
                  <strong class="name">
                    {{ userToDesactiver.end_week.week_nbr }}
                    ( {{ userToDesactiver.end_week.first_day_of_week | date }} à
                    {{ userToDesactiver.end_week.last_day_of_week | date }}
                    ) </strong
                  >.
                </span>
              </p>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? "Êtes-vous certain de vouloir l'activer ?"
                    : 'Are you sure you want to activate them ?'
                }}
              </p>
            </div>
          </div>
          <div v-else>
            <div v-if="userToDesactiver && userToDesactiver.is_active == 1">
              <p>
                {{
                  $t('msgDesactive', {
                    msg: $i18n.locale === 'fr' ? " l'utilisateur" : 'the user'
                  })
                }}
                <strong class="name">
                  {{ userToDesactiver ? userToDesactiver.first_name : '' }}
                  {{
                    userToDesactiver ? userToDesactiver.last_name : ''
                  }}</strong
                >
                ?
              </p>
            </div>
            <div v-else>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? "Êtes-vous certain de vouloir activer l'utilisateur"
                    : 'Are you sure you want to activate the user?'
                }}
                <strong class="name">
                  {{ userToDesactiver ? userToDesactiver.first_name : '' }}
                  {{
                    userToDesactiver ? userToDesactiver.last_name : ''
                  }}</strong
                >
                ?
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleModalDesactiveActiveValider"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('userDesactiveActiveModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Desactiver User Modal -->
    <!-- Update User Modal  -->
    <v-dialog v-model="userUpdateModal" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            $t('update') +
              ' ' +
              (userToUpdate ? userToUpdate.first_name_update : '') +
              ' ' +
              (userToUpdate ? userToUpdate.last_name_update : '')
          }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('userUpdateModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateUserForm" v-if="userToUpdate" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('first_name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.first_name"
                  :disabled="
                    computedRegie ||
                      currentUser.type == 'user.final' ||
                      currentUser.type == 'user.commercial'
                  "
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('first_name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.last_name"
                  :disabled="
                    computedRegie ||
                      currentUser.type == 'user.final' ||
                      currentUser.type == 'user.commercial'
                  "
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-text-field
                  label="Pipedrive api token"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.api_pipe_token"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Passerelle"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  disabled
                  v-model="userToUpdate.passerelle_id"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-text-field
                  :label="$t('phone')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.phone"
                  type="tel"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('zip_code')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.zipcode"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="E-mail"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.email"
                  required
                  validate-on-blur
                  :rules="[v => !!v || 'E-mail  ' + $t('msgOblg')]"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('password')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="password"
                  v-model="userToUpdate.password"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('confirm_password')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="password"
                  v-model="userToUpdate.password_confirmation"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <!-- CRM PIXEL -->
            <v-row v-if="computedVoirChamps">
              <v-col>
                <v-text-field
                  label="Code client"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.code_client"
                  required
                  item-color="#5C2DD3"
                  validate-on-blur
                  :rules="[v => !!v || 'Code client ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  :label="$t('company')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="userToUpdate.societe"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row v-if="computedVoirChamps">
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Nombre des appareils maximales'
                      : 'Maximum number of devices'
                  "
                  dense
                  v-model="userToUpdate.nombre_appareil"
                  item-text="name"
                  item-value="id"
                  :items="optionsAppareilMax"
                  outlined
                  :persistent-placeholder="true"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'Nombre des appareils maximales'
                        : 'Maximum number of devices') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col>
                <v-text-field
                  :label="
                    $i18n.locale === 'fr' ? 'Période d\'essai' : 'Trial period'
                  "
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToUpdate.periode_test"
                  :placeholder="
                    $i18n.locale === 'fr'
                      ? 'Choisir une valeur entre 1 et 30'
                      : 'Choose a value between 1 and 30'
                  "
                  min="1"
                  max="30"
                  item-color="#5C2DD3"
                >
                </v-text-field>
                <!-- <span class="label-text"> Choisir une valeur entre 1 et 30</span> --></v-col
              >
            </v-row>
            <v-row v-if="currentUser && currentUser.isSuper">
              <v-col>
                <v-select
                  v-if="currentUser.type != 'user.sub-super-admin'"
                  v-model="userToUpdate.type"
                  @input="handleChangeTypeUpdate"
                  label="Types"
                  dense
                  required
                  item-text="name"
                  item-value="id"
                  :items="computedFormatTypesUsers"
                  outlined
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <v-select
                  v-else-if="
                    currentUser.type == 'user.sub-super-admin' &&
                      userToUpdate.type != 'user.sub-super-admin'
                  "
                  v-model="userToUpdate.type"
                  @input="handleChangeTypeUpdate"
                  label="Types"
                  dense
                  required
                  item-text="name"
                  item-value="id"
                  :items="computedFormatTypesUsers"
                  outlined
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || 'Type ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
                <v-text-field
                  label="Types"
                  v-else-if="
                    currentUser.type == 'user.sub-super-admin' &&
                      userToUpdate.type == 'user.sub-super-admin'
                  "
                  :value="$options.filters.typeFormat(userToUpdate.type)"
                  disabled
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row
              v-if="userToUpdate.type && currentUser && currentUser.isSuper"
            >
              <v-col class="margin-switch-users mr-3">
                <v-switch
                  class="input-checkbox switch-bottom"
                  label="Export Excel"
                  color="#5C2DD3"
                  v-if="
                    userToUpdate.type == 'user.admin' ||
                      userToUpdate.type == 'user.sub-super-admin'
                  "
                  v-model="checkedExport"
                  :disabled="
                    userToUpdate.type == 'user.admin' ||
                      userToUpdate.type == 'user.sub-super-admin'
                  "
                >
                </v-switch>
                <v-switch
                  class="input-checkbox switch-bottom"
                  v-else
                  label="Export Excel"
                  color="#5C2DD3"
                  v-model="userToUpdate.has_export_excel"
                >
                </v-switch
              ></v-col>
              <v-col class="ml-3"
                ><v-switch
                  class="input-checkbox switch-bottom"
                  label="Import Excel"
                  color="#5C2DD3"
                  v-model="userToUpdate.has_import_excel"
                >
                </v-switch
              ></v-col>
            </v-row>

            <v-row
              v-if="
                computedRegie &&
                  userToUpdate.type != 'user.admin' &&
                  currentUser.type != 'user.commercial' &&
                  currentUser.type != 'user.final'
              "
            >
              <v-col>
                <v-select
                  label="Type"
                  dense
                  v-model="userToUpdate.typeRegie"
                  :disabled="computedRegie"
                  item-text="text"
                  item-value="id"
                  :items="usertypes"
                  outlined
                  :persistent-placeholder="true"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row
              v-if="
                userToUpdate.type == 'user.final' &&
                  currentUser.type != 'user.final' &&
                  currentUser.type != 'user.commercial'
              "
            >
              <v-col
                v-if="
                  listCommercailsUser &&
                    listCommercailsUser.data &&
                    listCommercailsUser.data.length
                "
              >
                <v-select
                  :label="$t('Superior')"
                  dense
                  v-model="userToUpdate.superior_id"
                  required
                  item-text="name"
                  item-value="id"
                  :items="listCommercailsUserUpdate.data || []"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || $t('Superior') + ' ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col v-else>
                <v-select
                  :label="$t('Superior')"
                  dense
                  v-model="userToUpdate.superior_id"
                  required
                  item-text="id"
                  item-value="id"
                  :items="listCommercailsUserUpdate.data || []"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  validate-on-blur
                  :rules="[v => !!v || $t('Superior') + ' ' + $t('msgOblg')]"
                  class="msg-error mt-2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row v-if="userToUpdate.type == 'user.technicien'">
              <v-col>
                <v-select
                  label="Co-Poseur"
                  dense
                  v-model="userToUpdate.co_technician_id"
                  @input="typeUsers"
                  item-text="full_name"
                  item-value="id"
                  :items="[...computedListTechniciens]"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Techniciens dans pipedrive'
                      : 'Pipedrive technicians'
                  "
                  dense
                  v-model="userToUpdate.technician_id_pipe"
                  item-text="label"
                  item-value="id_tech_pipe"
                  :items="computedTechnicianspipedrive"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>

            <v-row v-if="userToUpdate.type == 'user.commercial.ite'">
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Commerciaux dans Pipedrive'
                      : 'Pipedrive Sales'
                  "
                  dense
                  v-model="userToUpdate.commercial_ite_id_pipe"
                  item-text="label"
                  item-value="id_comm_ite_pipe"
                  :items="computedCommerciauxpipedrive"
                  outlined
                  :no-data-text="$t('noDataOption')"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>

            <v-row
              v-if="
                userToUpdate.type == 'user.commercial' &&
                  currentUser.type != 'user.commercial'
              "
            >
              <v-col>
                <v-text-field
                  label="Commission 101"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToUpdate.coeff_101"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  label="Commission 102"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToUpdate.coeff_102"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  label="Commission 103"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  type="number"
                  v-model="userToUpdate.coeff_103"
                  item-color="#5C2DD3"
                >
                </v-text-field
              ></v-col>
            </v-row>
            <v-row
              v-if="
                currentUser &&
                  currentUser.isSuper &&
                  (userToUpdate.type == 'user.commercial' ||
                    userToUpdate.type == 'user.final')
              "
            >
              <v-col>
                <v-select
                  :label="$i18n.locale === 'fr' ? 'Proprietaire' : 'Owner'"
                  dense
                  v-model="userToUpdate.owner_id"
                  item-text="full_name"
                  item-value="id"
                  :items="property"
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select
              ></v-col>
            </v-row>
            <v-row
              v-if="
                currentUser &&
                  currentUser.isSuper &&
                  (userToUpdate.type == 'user.admin' ||
                    userToUpdate.type == 'user.agent' ||
                    userToUpdate.type == 'user.resp.plan' ||
                    userToUpdate.type == 'user.administration')
              "
            >
              <v-col>
                <v-select
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Utilisateur pipedrive'
                      : 'Pipedrive user'
                  "
                  dense
                  v-model="userToUpdate.pipe_user_id"
                  item-text="name"
                  item-value="id"
                  :no-data-text="$t('noDataOption')"
                  :items="[...computedUtilisateurPipedrive]"
                  outlined
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('userUpdateModal')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update User Modal  -->
  </div>
</template>
<script>
// import Multiselect from 'vue-multiselect'
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  components: {
    // Multiselect
  },
  data() {
    return {
      activeUser: true,
      exportUserModal: false,
      userDeleteModal: false,
      userDesactiveActiveModal: false,
      userUpdateModal: false,
      perPageList: [10, 20, 30, 50],
      selectedTable: [],
      userToUpdate: {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        owner_id: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        code_client: null,
        societe: null,
        nombre_appareil: 11,
        periode_test: null
      },
      optionsAppareilMax: [
        { id: 5, name: '5' },
        { id: 10, name: '10' },
        { id: 11, name: 'Plus de 10' }
      ],
      fields: [
        { value: 'first_name', text: 'Utilisateur', sortable: false },
        { value: 'email', text: 'E-mail', sortable: false },
        { value: 'type', text: 'Type', sortable: false },
        { value: 'team_name', text: 'Equipe', sortable: false },
        { value: 'created_at', text: 'Créé le', sortable: false },
        { value: 'created_by', text: 'Créé par', sortable: false },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      page: 1,
      per_page: 10,
      active: 1,
      sort_by: 'first_name',
      sort_by_desc: 'first_name',
      sortDesc: false,
      start_week_id: null,
      end_week_id: null,
      full_name: '',
      userToDelete: null,
      userToDesactiver: null,
      loading: false,
      initLoading: true,
      error: null,
      checkedExport: true,
      userToExport: {
        type: { id: null, name: 'Tous' },
        team_type: null,
        active: null,
        columns: []
      },
      teamTypesStatique: [
        {
          id: 'with-team',
          name: 'Tous les equipes'
        },
        {
          id: 'without-team',
          name: 'Sans equipes'
        },
        {
          id: 'with-without-team',
          name: 'Avec ou Sans equipes'
        }
      ],
      attributes: [
        'ID',
        'PASSERELLE ID',
        'PRÉNOM',
        'NOM',
        'EMAIL',
        'ROLE',
        "NOM DE L'ÉQUIPE",
        "TYPE D'ÉQUIPE"
      ],
      requiredColumns: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: this.usersCount
      }
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'updateUser',
      'getUsersType',
      'getlistCommercailsUser',
      'getlistCommercailsUserUpdate',
      'deleteUser',
      'getTechnicianspipedrive',
      'getCommerciauxPipedrive',
      'getUsersPipedrive',
      'activerDesactiverUser',
      'getlistWeeks',
      'getTechniciansPipeUpdate',
      'getCommerciauxPipeUpdate',
      'fetchUsersTechniciens',
      'fetchProprieteRegie',
      'getteamsTypes',
      'exportUser'
    ]),
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'exportUserModal') {
        this.$refs.validateExportUser.resetValidation()
      }
      if (ref == 'userUpdateModal') {
        this.$refs.updateUserForm.resetValidation()
      }
      this.resetModal()
    },
    isDisabled(item) {
      if (
        this.userToExport.team_type &&
        this.userToExport.team_type.id == 'without-team' &&
        (item == "NOM DE L'ÉQUIPE" || item == "TYPE D'ÉQUIPE")
      ) {
        return true
      }
      return false
    },
    handleClickDelete(data_item) {
      this.userToDelete = data_item
      this.userDeleteModal = true
    },
    handleClickDesactiveActive(data_item) {
      this.userToDesactiver = { ...data_item }
      if (this.userToDesactiver.end_week_id) {
        this.weeks.forEach(week => {
          if (week.id == this.userToDesactiver.end_week_id) {
            this.userToDesactiver.end_week = week
          }
        })
      }
      this.weeks.forEach(week => {
        if (week.id == this.userToDesactiver.start_week_id) {
          this.userToDesactiver.start_week = week
        }
      })
      this.userDesactiveActiveModal = true
    },
    handleClickUpdate(data_item) {
      this.userToUpdate = { ...data_item }
      if (this.userToUpdate && this.userToUpdate.type == 'user.final') {
        this.getlistCommercailsUserUpdate(this.userToUpdate.id)
      }
      this.userUpdateModal = true
      this.userToUpdate.last_name_update = this.userToUpdate.last_name
      this.userToUpdate.first_name_update = this.userToUpdate.first_name
      if (
        this.userToUpdate.type == 'user.final' ||
        this.userToUpdate.type == 'user.commercial'
      ) {
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        if (this.computedRegie) {
          if (this.userToUpdate.type == 'user.final') {
            this.userToUpdate.typeRegie = 'Utilisateur Final'
          } else {
            this.userToUpdate.typeRegie = 'Commercial'
          }
        }
      }
    },
    handleChangeTypeUpdate() {
      this.userToUpdate.has_export_excel = 0
      this.userToUpdate.has_import_excel = 0
      if (this.userToUpdate && this.userToUpdate.type == 'user.final') {
        this.getlistCommercailsUserUpdate(this.userToUpdate.id)
      }
    },
    async handleModalSupprimerValider() {
      this.loading = true
      this.error = null
      const response = await this.deleteUser(this.userToDelete.id)
      if (response.success) {
        this.loading = false
        this.closeDialog('userDeleteModal')
        if (!this.computedVoirChamps) {
          this.getlistCommercailsUser()
        }
        this.getTechnicianspipedrive()
        this.getUsersPipedrive()
        this.fetchProprieteRegie({
          exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
        })
        this.fetchUsersTechniciens()
        this.getCommerciauxPipedrive()
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    async handleModalDesactiveActiveValider() {
      this.loading = true
      this.error = null
      const response = await this.activerDesactiverUser(this.userToDesactiver)
      if (response.success) {
        this.loading = false
        this.closeDialog('userDesactiveActiveModal')
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
        this.userToDesactiver = null
      } else {
        this.loading = false
        this.error = response.error
      }
    },
    resetModal(updatePipeUsers = false) {
      this.userToUpdate = {
        first_name: null,
        last_name: null,
        last_name_update: null,
        first_name_update: null,
        email: null,
        api_pipe_token: null,
        password: null,
        password_confirmation: null,
        type: '',
        typeRegie: '',
        superior_id: null,
        coeff_101: null,
        coeff_102: null,
        coeff_103: null,
        owner_id: null,
        co_technician_id: null,
        technician_id_pipe: null,
        commercial_ite_id_pipe: null,
        pipe_user_id: null,
        phone: null,
        zipcode: null,
        has_export_excel: 0,
        has_import_excel: 0,
        code_client: null,
        societe: null,
        nombre_appareil: 11,
        periode_test: null
      }
      this.fields = [
        { value: 'first_name', text: 'Nom', sortable: true },
        { value: 'email', text: 'E-mail', sortable: true },
        { value: 'type', text: 'Type', sortable: false },
        { value: 'team_name', text: 'Equipe', sortable: false },
        { value: 'created_at', text: 'Créé le', sortable: false },
        { value: 'created_by', text: 'Créé par', sortable: false },
        { value: 'actions', text: '', sortable: false }
      ]
      this.start_week_id = null
      this.end_week_id = null
      this.userToDelete = null
      this.userToDesactiver = null
      this.userToUpdate = null
      this.loading = false
      this.error = null
      this.userToExport = {
        type: { id: null, name: 'Tous' },
        team_type: null,
        active: null,
        columns: []
      }
      this.requiredColumns = false
      if (updatePipeUsers) {
        this.getUsersPipedrive()
      }
    },
    async handleSubmitUpdate() {
      if (this.$refs.updateUserForm.validate()) {
        this.loading = true
        this.error = null
        if (this.userToUpdate.password) {
          if (
            this.userToUpdate.password !=
            this.userToUpdate.password_confirmation
          ) {
            this.loading = false
            this.error = [
              this.$i18n.locale === 'fr'
                ? 'le mot de passe et la confirmation du mot de passe doivent être égaux'
                : 'password and password confirmation must be equal'
            ]
            return
          }
          if (this.userToUpdate.password.length < 6) {
            this.loading = false
            this.error = [
              this.$i18n.locale === 'fr'
                ? 'le mot de passe doit contenir au moins 6 caractères'
                : 'password must contain at least 6 characters'
            ]
            return
          }
        }
        const response = await this.updateUser(this.userToUpdate)
        if (response.success) {
          this.loading = false
          this.getTechnicianspipedrive()
          this.getUsersPipedrive()
          this.fetchProprieteRegie({
            exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
          })
          this.fetchUsersTechniciens()
          this.getCommerciauxPipedrive()
          this.closeDialog('userUpdateModal')
          if (!this.sortDesc) {
            this.getUsers({
              page: this.page,
              per_page: this.per_page,
              sort_by: this.sort_by,
              full_name: this.full_name,
              active: this.active,
              exclude: ['team.manager', 'team.depots', 'visibleTeams']
            })
          } else {
            this.getUsers({
              page: this.page,
              per_page: this.per_page,
              sort_by_desc: this.sort_by_desc,
              full_name: this.full_name,
              active: this.active,
              exclude: ['team.manager', 'team.depots', 'visibleTeams']
            })
          }
        } else {
          this.loading = false
          this.error = response.error
        }
      }
    },
    SortUsers(newPerPage) {
      this.page = 1
      this.pagination.itemsPerPage = newPerPage
      this.pagination.page = 1
      this.per_page = newPerPage
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active
        })
      }
    },
    handlClickActive(state) {
      this.active = state == true ? 1 : 0
      if (this.active === 1) {
        this.page = 1
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.active = state
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      } else {
        this.page = 1
        if (!this.sortDesc) {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by: this.sort_by,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        } else {
          this.getUsers({
            page: this.page,
            per_page: this.per_page,
            sort_by_desc: this.sort_by_desc,
            full_name: this.full_name,
            active: this.active,
            exclude: ['team.manager', 'team.depots', 'visibleTeams']
          })
        }
      }
    },
    async exportClickUser() {
      this.exportUserModal = true
      this.userToExport.type = { id: null, name: 'Tous' }
      await this.getteamsTypes()
    },
    async handleExportUser() {
      if (this.$refs.validateExportUser.validate()) {
        let ObjectExportUser = {}
        let columnsNew = this.userToExport.columns.filter(item => item != false)
        ObjectExportUser.columns = columnsNew.filter(item => item != null)
        if (this.userToExport.columns && ObjectExportUser.columns.length > 0) {
          this.requiredColumns = false

          if (this.userToExport.active) {
            ObjectExportUser.active = this.userToExport.active
          }
          if (this.userToExport.team_type) {
            ObjectExportUser.team_type = this.userToExport.team_type.id
          }
          if (typeof this.userToExport.type !== 'object') {
            ObjectExportUser.type = this.userToExport.type
          }

          this.loading = true
          this.error = null
          const response = await this.exportUser({ payload: ObjectExportUser })
          this.loading = false
          if (!response.success) {
            this.error = response.error
          } else {
            this.closeDialog('exportUserModal')
          }
        } else {
          this.requiredColumns = true
        }
      }
    },
    filter() {
      this.page = 1
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    sort(sort) {
      this.sort_by = sort.sortBy
      this.sort_by_desc = sort.sortBy
      if (!sort.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    },
    handlePaginationChange(paginate) {
      this.page = paginate
      this.pagination.page = 1
      if (!this.sortDesc) {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      } else {
        this.getUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name,
          active: this.active,
          exclude: ['team.manager', 'team.depots', 'visibleTeams']
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'users',
      'usertypes',
      'listCommercailsUser',
      'listCommercailsUserUpdate',
      'technicianspipedrive',
      'commerciauxpipedrive',
      'usersPipedriveNoSelectionne',
      'weeks',
      'usersCount',
      'getUserLoading',
      'getProjectsUsersTechniciens',
      'property',
      'currentUser',
      'teamsTypes'
    ]),
    computedFormatTypesUsersExport() {
      if (this.usertypes && this.usertypes.length) {
        const options = []

        // Ajouter "Tous" en premier
        options.push({ id: null, name: 'Tous' })

        for (const group of this.usertypes) {
          if (Array.isArray(group.types)) {
            let objetGroupe = { header: group.groupe }
            options.push(
              objetGroupe,
              ...group.types.map(type => ({ ...type, groupe: group.groupe }))
            )
          }
        }

        return options
      }
      return []
    },
    computedFormatTypesUsers() {
      if (this.usertypes && this.usertypes.length) {
        const options = []
        for (const group of this.usertypes) {
          if (Array.isArray(group.types)) {
            let objetGroupe = { header: group.groupe }
            options.push(
              objetGroupe,
              ...group.types.map(type => ({ ...type, groupe: group.groupe }))
            )
          }
        }
        return options
      }
      return []
    },
    totalPages() {
      if (this.usersCount) {
        return Math.ceil(this.usersCount / this.per_page)
      }
      return this.usersCount
    },
    computedFields: function() {
      if (this.currentUser && this.currentUser.code_client != null) {
        let fields = [
          {
            value: 'first_name',
            text: 'Nom',
            sortable: false
          },
          { value: 'email', text: 'E-mail', sortable: false },
          {
            value: 'code_client',
            text: 'Code client',
            sortable: false,
            thClass: 'width-th-code',
            tdClass: 'width-th-code'
          },
          {
            value: 'societe',
            text: 'Société',
            sortable: false,
            thClass: 'width-th',
            tdClass: 'width-th'
          },

          {
            value: 'created_at',
            text: 'Créé le',
            sortable: false
          },
          {
            value: 'created_by',
            text: 'Créé par',
            sortable: false
          },
          { value: 'actions', text: 'Actions', sortable: false }
        ]
        return fields
      }
      return [
        {
          value: 'first_name',
          text: 'Utilisateur',
          sortable: false
        },
        { value: 'email', text: 'E-mail', sortable: false },
        { value: 'type', text: 'Type', sortable: false },
        {
          value: 'team_name',
          text: 'Equipe',
          sortable: false
        },
        {
          value: 'created_at',
          text: 'Créé le',
          sortable: false
        },
        {
          value: 'created_by',
          text: 'Créé par',
          sortable: false
        },
        { value: 'actions', text: 'Actions', sortable: false }
      ]
    },
    computedFieldsEn: function() {
      if (this.currentUser && this.currentUser.code_client != null) {
        let fields = [
          {
            value: 'first_name',
            text: 'Name',
            sortable: false
          },
          { value: 'email', text: 'E-mail', sortable: false },
          {
            value: 'code_client',
            text: 'client code',
            sortable: false,
            thClass: 'width-th-code',
            tdClass: 'width-th-code'
          },
          {
            value: 'societe',
            text: 'Company',
            sortable: false,
            thClass: 'width-th',
            tdClass: 'width-th'
          },

          {
            value: 'created_at',
            text: 'Created on',
            sortable: false
          },
          {
            value: 'created_by',
            text: 'Created by',
            sortable: false
          },
          { value: 'actions', text: 'Actions', sortable: false }
        ]
        return fields
      }
      return [
        {
          value: 'first_name',
          text: 'User',
          sortable: false
        },
        { value: 'email', text: 'E-mail', sortable: false },
        { value: 'type', text: 'Type', sortable: false },
        {
          value: 'team_name',
          text: 'Team',
          sortable: false
        },
        {
          value: 'created_at',
          text: 'Created on',
          sortable: false
        },
        {
          value: 'created_by',
          text: 'Created by',
          sortable: false
        },
        { value: 'actions', text: 'Actions', sortable: false }
      ]
    },
    computedVoirChamps: function() {
      if (
        this.currentUser &&
        this.currentUser.code_client != null &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie' &&
        this.currentUser.type == 'user.commercial'
      ) {
        return true
      }
      return false
    },
    computedusersSpecifierVoirChampsAndSuperAdminfunction() {
      if (
        this.computedVoirChamps ||
        (this.currentUser && this.currentUser.isSuper)
      ) {
        return true
      }
      return false
    },
    computedExportUser: function() {
      return [
        {
          id: 'with-team',
          name: 'Tous les equipes'
        },
        {
          id: 'without-team',
          name: 'Sans equipes'
        },
        {
          id: 'with-without-team',
          name: 'Avec ou Sans equipes'
        },
        ...this.teamsTypes
      ]
    },

    computedWeeks: function() {
      const currentWeek = moment().week()
      return this.weeks
        .filter(week => {
          if (
            week.id >= currentWeek &&
            week.last_day_of_week >= week.first_day_of_week
          ) {
            return week
          }
        })
        .map(item => {
          item.name = `Semaine ${item.week_nbr} (
                      ${this.$options.filters.date(item.first_day_of_week)} à
                      ${this.$options.filters.date(item.last_day_of_week)} )`
          return { ...item }
        })
    },
    computedTechnicianspipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.technicianPipe &&
        this.userToUpdate.technicianPipe.data
      ) {
        return [
          this.userToUpdate.technicianPipe.data,
          ...this.technicianspipedrive
        ]
      }
      return this.technicianspipedrive
    },
    computedCommerciauxpipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.commercialItePipe &&
        this.userToUpdate.commercialItePipe.data
      ) {
        return [
          this.userToUpdate.commercialItePipe.data,
          ...this.commerciauxpipedrive
        ]
      }
      return this.commerciauxpipedrive
    },
    computedUtilisateurPipedrive: function() {
      if (
        this.userToUpdate &&
        this.userToUpdate.userPipe &&
        this.userToUpdate.userPipe.data
      ) {
        return [
          this.userToUpdate.userPipe.data,
          ...this.usersPipedriveNoSelectionne
        ]
      }
      return this.usersPipedriveNoSelectionne
    },
    computedListTechniciens: function() {
      return this.getProjectsUsersTechniciens.filter(
        t => t.id != this.userToUpdate.id
      )
    },
    computedRegie: function() {
      return (
        this.currentUser.type == 'user.admin' &&
        this.currentUser.details &&
        this.currentUser.details.team &&
        this.currentUser.details.team.data &&
        this.currentUser.details.team.data.type === 'regie'
      )
    }
  },
  async mounted() {
    this.initLoading = true
    await this.getUsers({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by,
      active: this.active,
      exclude: ['team.manager', 'team.depots', 'visibleTeams']
    })
    this.initLoading = false
    this.getlistWeeks()
    this.getUsersType()
    if (!this.computedVoirChamps) {
      this.getlistCommercailsUser()
    }
    this.getTechnicianspipedrive()
    this.getCommerciauxPipedrive()
    this.getTechniciansPipeUpdate()
    this.getCommerciauxPipeUpdate()
    this.getUsersPipedrive()
    this.fetchUsersTechniciens()
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'GE Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'Filiale Commercial'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        default:
          return ''
      }
    },
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.users-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .header-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // padding-bottom: 11px;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .activer-desactiver-user {
    display: flex;
    align-items: center;
    .color-bold {
      .v-label {
        color: #3a167f !important;
        font-size: 14px !important;
        font-family: 'Montserrat', sans-serif !important;
        line-height: 25px !important;
        letter-spacing: 0.15px !important;
      }
    }
    .button-activer {
      font-size: 13px;
      padding: 3.375px 5.75px;
    }
    .button-desactiver {
      margin-left: 8px;
      font-size: 13px;
      padding: 3.375px 5.75px;
    }
  }
  .input-search {
    border: 1px solid #dadada;
    outline: none;
    font-size: 16px;
    height: 40px;
    background: #fff;
    padding-left: 10px;
  }
  .icon-exclamation {
    text-decoration: line-through !important;
    text-decoration-color: #5c2dd3 !important;
  }
  .name {
    text-transform: capitalize;
  }
  .equipe {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
}
</style>
<style lang="scss">
.margin-switch-users {
  margin-left: 40px !important;
}
.activer-desactiver-user {
  display: flex;
  align-items: center;
  .v-input--switch__thumb {
    color: #fff !important;
    caret-color: #fff !important;
  }
  .color-bold {
    .v-label {
      color: #3a167f !important;
      font-size: 16px !important;
      font-family: 'Montserrat', sans-serif !important;
      line-height: 25px !important;
      letter-spacing: 0.15px !important;
    }
  }
}
.table-users {
  table {
    border-collapse: collapse;
  }

  height: 100% !important;
  max-height: 100% !important;
  .header-users {
    th {
      text-align: left;
      padding: 0px 14px;
      height: 40px;
      font-size: 14px;
      white-space: nowrap;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  thead {
    tr {
      th {
        white-space: nowrap;
        color: rgb(0 0 0) !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: 12px !important;
        letter-spacing: 0.17px;
        line-height: 17.16px;
        font-family: 'Roboto';
      }
    }
  }
  .v-data-table__wrapper::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .v-data-table__wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .v-data-table__wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
  .v-data-table__wrapper {
    max-height: calc(100vh - 380px) !important;
    height: calc(100vh - 380px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
  }
}
</style>
